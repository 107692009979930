import React from "react"
import Obfuscate from 'react-obfuscate';

import Layout from "../components/layout"
import Seo from "../components/seo"

import PhoneIcon from "../assets/svg/icons/phone.svg"
import EmailIcon from "../assets/svg/icons/mail.svg"

class Team extends React.Component {
	render() {
		return (
			<Layout>
				<Seo title="Team" />
				<section className="page">
					<h1 className="text_huge">Team</h1>

					<div className="flexbox flex-half">
						<ul className="nodotList">
							<li className="text_big">Amon Vogel</li>
							<li className="with-icon"><EmailIcon /><Obfuscate email="vogel@netengine.at" /></li>
							<li className="with-icon"><PhoneIcon /><Obfuscate tel="004355742253811">+43 (0)5574 22538 - 11</Obfuscate></li>
						</ul>
						<ul className="nodotList">
							<li className="text_big">Ulf Albl</li>
							<li className="with-icon"><EmailIcon /><Obfuscate email="albl@netengine.at" /></li>
							<li className="with-icon"><PhoneIcon /><Obfuscate tel="004355742253812">+43 (0)5574 22538 - 12</Obfuscate></li>
						</ul>
						<ul className="nodotList">
							<li className="text_big">Tina Vogler</li>
							<li className="with-icon"><EmailIcon /><Obfuscate email="vogler@netengine.at" /></li>
							<li className="with-icon"><PhoneIcon /><Obfuscate tel="004355742253814">+43 (0)5574 22538 - 14</Obfuscate></li>
						</ul>
						<ul className="nodotList">
							<li className="text_big">Ali Güler</li>
							<li className="with-icon"><EmailIcon /><Obfuscate email="gueler@netengine.at" /></li>
							<li className="with-icon"><PhoneIcon /><Obfuscate tel="004355742253815">+43 (0)5574 22538 - 15</Obfuscate></li>
						</ul>
						<ul className="nodotList">
							<li className="text_big">Isabelle Scheffner</li>
							<li className="with-icon"><EmailIcon /><Obfuscate email="scheffner@netengine.at" /></li>
							<li className="with-icon"><PhoneIcon /><Obfuscate tel="004355742253815">+43 (0)5574 22538 - 16</Obfuscate></li>
						</ul>
						{/* <li ><small>derzeit in Karenz</small></li> */}
					</div>

				</section>
			</Layout>
		)
	}
}

export default Team
